:root {
  /* The main font with fallbacks, used for everything if no secondary font is defined */
  --font-main: "Gilroy", sans-serif;
  /* The main brand color, usually the logo color */
  --color-main: #7fe6af;
  /* A secondary color used as accent or contrast */
  --color-secondary: #9075ff;
  /* Used for example as background color of the site */
  --color-neutral: #143330;
  --color-neutral-complement: #21715d;
  --color-neutral-complement-25: color-mod(
    var(--color-neutral-complement) alpha(25%)
  );
  --gradient-neutral: linear-gradient(
    150deg,
    var(--color-neutral),
    var(--color-neutral-complement)
  );
  --color-neutral-25: color-mod(var(--color-neutral) alpha(25%));
  --color-main-25: color-mod(var(--color-main) alpha(25%));
  --color-main-50: color-mod(var(--color-main) alpha(50%));
  --color-main-75: color-mod(var(--color-main) alpha(75%));
  --color-secondary-25: color-mod(var(--color-secondary) alpha(25%));

  --border-main: 30px;
  --border-main-top: 250px;
  --font-size-xxs: 0.5em;
  --font-size-xs: 0.8em;
  --font-size-s: 1.2em;
  --font-size-m: 1.8em;
  --font-size-xm: 2em;
  --font-size-xxm: 2.5em;
  --font-size-l: 3em;
  --font-size-xl: 2em;
  --font-size-xxl: 6em;
}

@media (--desktop-viewport) {
  :root {
    --font-size-xl: 4em;
    --border-main: 100px;
  }
}

* {
  box-sizing: border-box;
}

html {
  background-color: var(--color-neutral);
}

html,
body,
#root {
  width: 100vw;
  overflow-x: hidden;
  margin: 0;
}

#root {
  scroll-behavior: smooth;
}

@custom-selector :--headings h1, h2, h3, h4, h5, h6;
@custom-media --desktop-viewport screen and (min-width: 900px);
@custom-media --desktop-large-viewport screen and (min-width: 1400px);
@custom-media --desktop-extra-large-viewport screen and (min-width: 1700px);
@custom-media --desktop-ultra-wide-viewport screen and (min-width: 2100px);
@custom-media --tablet-viewport screen and (min-width: 650px);
@custom-media --tablet-large-viewport screen and (min-width: 750px);
@custom-media --mobile-small-viewport screen and (max-width: 400px);
@custom-media --horizontal-viewport screen and (max-aspect-ratio: 1/1);
@custom-media --vertical-viewport screen and (min-aspect-ratio: 1/1);

:--headings,
p,
input,
ul,
ol {
  color: var(--color-main);
  margin: 0;
  font-family: var(--font-main);
}

ul,
ol {
  margin-bottom: 3em;
}

p {
  max-width: 900px;
  margin-bottom: 3em;
}

:--headings {
  color: var(--color-main);
  margin: 0;
  line-height: 1.1;
  font-family: var(--font-main);
}

h1 {
  font-weight: 800;
  font-size: var(--font-size-xl);
}

h2 {
  margin-bottom: 2em;
}

h3 {
  margin-bottom: 2em;
}
