.Image-module_container__JJkAx {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

    .Image-module_container__JJkAx img {
        -o-object-fit: cover;
           object-fit: cover;
        width: 100%;
        height: 100%;
    }

.Form-module_container__2y6-T {
    --field-padding-x: 32px;
    --field-padding: 0em var(--field-padding-x);
    --field-height: 60px;
}

    .Form-module_container__2y6-T .Form-module_fieldsContainer__2NsNW {
        transform-origin: top;
        height: 100%;
        display: grid;
        grid-gap: 3em;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
    }

    @media screen and (min-width: 900px) {
        .Form-module_container__2y6-T .Form-module_fieldsContainer__2NsNW {
            display: grid;
            grid-gap: 3em;
            grid-template-rows: 1fr;
            grid-template-columns: 1fr;
        }
}

    @media screen and (min-width: 900px) {
    .Form-module_container__2y6-T {
        --field-padding: 0em 32px;
        --field-height: 90px;
    }
}

.Section-module_container__31g6j {
    position: relative;
    padding: 0 var(--border-main);
}
    .Section-module_container__31g6j .Section-module_innerContainer__yLfrv {
        height: 100%;
    }

.Text-module_container__11LG7 {
    display: block;
    color: var(--color-main)
}
.Text-module_container__11LG7.Text-module_neutral__3h9YO {
        color: var(--color-neutral);
    }
.Text-module_container__11LG7.Text-module_secondary__1ur6I {
        color: var(--color-secondary);
    }

.Logo-module_container__2qvGr {
    width: 140px;
    -webkit-filter: drop-shadow(0px 5px 10px var(--color-main-50));
            filter: drop-shadow(0px 5px 10px var(--color-main-50));
    display: block;
    cursor: pointer;
    position: absolute;
    z-index: 10;
}

    .Logo-module_container__2qvGr svg path {
            fill: url(#unom-logo-gradient);
        }

    @media screen and (min-width: 900px) {
    .Logo-module_container__2qvGr {
        width: 200px;
    }
}

.Slider-module_container__5ilsg {
}

.Link-module_container__3Tsrk .Link-module_link___0Wn4 {
        color: var(--color-main) !important;
    }
.Link-module_container__3Tsrk.Link-module_secondary__3RrJE .Link-module_link___0Wn4 {
            color: var(--color-secondary) !important;
        }
.Link-module_container__3Tsrk.Link-module_neutral__34elH .Link-module_link___0Wn4 {
            color: var(--color-neutral);
        }

.Footer-module_container__Ig6aT {
    height: 100%;
    padding: var(--border-main);
    background-color: var(--color-neutral);
}

.Button-module_container__1D6ar {
    display: block;
    transform-origin: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background-color: var(--color-secondary);
}
    .Button-module_container__1D6ar .Button-module_button__2ELNY {
        font-size: var(--font-size-s);
        display: block;
        background: transparent;
        border: none;
        color: var(--color-main) !important;
    }

.Select-module_container__1S70u {
    height: var(--field-height);
    width: 100%;
    position: relative;
    transform-origin: left top;
}

    .Select-module_container__1S70u select {
        width: 100%;
        border-radius: 0;
        border: none;
        font-size: var(--font-size-m);
        background-color: var(--color-main);
        outline-color: var(--color-secondary-50);
        outline-style: solid;
        padding: var(--field-padding);
        outline-width: 2px;
        box-shadow: 0px 0px 0px var(--color-secondary-25);
        transition: outline 0.7s var(--ease-out-quint), box-shadow 0.7s var(--ease-out-quint);
        height: 100%;
        color: var(--color-secondary);
        -webkit-appearance: none;
           -moz-appearance: none;
                appearance: none
    }

    .Select-module_container__1S70u select:focus {
            border: none;
            box-shadow: var(--box-shadow-secondary);
            outline-color: var(--color-secondary);
            outline-offset: 0px;
        }

    .Select-module_container__1S70u .Select-module_arrow__2hKkl {
        border-color: var(--color-secondary-50) transparent;
        border-style: solid;
        border-width: 15px 10px 0px 10px;
        height: 0px;
        width: 0px;
        position: absolute;
        right: var(--field-padding-x);
        top: calc(5px + var(--field-padding-x) / 1.5);
    }

    .Select-module_container__1S70u .Select-module_label__2gT3_ {
        cursor: pointer;
        position: absolute;
        font-size: var(--font-size-m);
        margin: auto;
        -webkit-user-select: none;
           -moz-user-select: none;
            -ms-user-select: none;
                user-select: none;
        pointer-events: none;
        transform-origin: left;
        display: flex;
        padding: var(--field-padding);
        align-items: center;
        justify-content: center;
        height: 100%;
    }

    .Select-module_container__1S70u.Select-module_dirty__1Vr4n.Select-module_invalid__3RGRF select {
                outline-color: var(--color-error);
            }

    .Select-module_container__1S70u.Select-module_dirty__1Vr4n.Select-module_invalid__3RGRF .Select-module_label__2gT3_ {
                color: var(--color-error);
            }

    @media screen and (min-width: 900px) {
        .Select-module_container__1S70u .Select-module_arrow__2hKkl {
            border-width: 20px 15px 0px 15px;
            top: calc(5px + var(--field-padding-x));
        }
}

.Input-module_container__3rDlW {
    width: 100%;
    transform-origin: left;
    height: var(--field-height);
    transform-origin: left top;
    position: relative;
}

    .Input-module_container__3rDlW input {
        width: 100%;
        border: none;
        color: var(--color-secondary);
        outline-color: var(--color-secondary-50);
        outline-style: solid;
        height: 100%;
        font-size: var(--font-size-xm);
        background-color: var(--color-main);
        padding: 0em 32px;
        outline-width: 2px;
        box-shadow: 0px 0px 0px var(--color-secondary-25);
        transition: outline 0.7s var(--ease-out-quint), box-shadow 0.7s var(--ease-out-quint)
    }

    .Input-module_container__3rDlW input:focus {
            border: none;
            box-shadow: var(--box-shadow-secondary);
            outline-color: var(--color-secondary);
            outline-offset: 0px;
        }

    .Input-module_container__3rDlW .Input-module_checkbox__3HQFd {
        width: 32px;
        height: 32px;
    }

    .Input-module_container__3rDlW .Input-module_label__fJwQC {
        -webkit-user-select: none;
           -moz-user-select: none;
            -ms-user-select: none;
                user-select: none;
        cursor: text;
        position: absolute;
        font-size: var(--font-size-m);
        margin: auto;
        transform-origin: left;
        display: flex;
        padding: var(--field-padding);
        align-items: center;
        justify-content: center;
        height: 100%;
    }

    .Input-module_container__3rDlW.Input-module_dirty__3iiiX.Input-module_invalid__2WJSz input {
                outline-color: var(--color-error);
            }

    .Input-module_container__3rDlW.Input-module_dirty__3iiiX.Input-module_invalid__2WJSz .Input-module_label__fJwQC {
                color: var(--color-error);
            }

.Checkbox-module_container__3PjtG {
    height: var(--field-height);
    width: var(--field-height);
    position: relative;
    transform-origin: left top;
}

    .Checkbox-module_container__3PjtG input {
        width: 100%;
        border-radius: 0;
        border: none;
        font-size: var(--font-size-m);
        background-color: var(--color-main);
        outline-color: var(--color-secondary-50);
        outline-style: solid;
        padding: var(--field-padding);
        outline-width: 2px;
        box-shadow: 0px 0px 0px var(--color-secondary-25);
        transition: outline 0.7s var(--ease-out-quint), box-shadow 0.7s var(--ease-out-quint);
        height: 100%;
        color: var(--color-secondary);
        -webkit-appearance: none;
           -moz-appearance: none;
                appearance: none
    }

    .Checkbox-module_container__3PjtG input:checked {
            background-color: red;
        }

    .Checkbox-module_container__3PjtG input:focus {
            border: none;
            box-shadow: var(--box-shadow-secondary);
            outline-color: var(--color-secondary);
            outline-offset: 0px;
        }

    .Checkbox-module_container__3PjtG .Checkbox-module_arrow__1wZIj {
        border-color: var(--color-secondary-50) transparent;
        border-style: solid;
        border-width: 15px 10px 0px 10px;
        height: 0px;
        width: 0px;
        position: absolute;
        right: var(--field-padding-x);
        top: calc(5px + var(--field-padding-x) / 1.5);
    }

    .Checkbox-module_container__3PjtG .Checkbox-module_label__11iuL {
        cursor: pointer;
        position: absolute;
        font-size: var(--font-size-m);
        margin: auto;
        -webkit-user-select: none;
           -moz-user-select: none;
            -ms-user-select: none;
                user-select: none;
        pointer-events: none;
        transform-origin: left;
        display: flex;
        padding: var(--field-padding);
        align-items: center;
        justify-content: center;
        height: 100%;
    }

    .Checkbox-module_container__3PjtG.Checkbox-module_dirty__2dQJX.Checkbox-module_invalid__1vuvA select {
                outline-color: var(--color-error);
            }

    .Checkbox-module_container__3PjtG.Checkbox-module_dirty__2dQJX.Checkbox-module_invalid__1vuvA .Checkbox-module_label__11iuL {
                color: var(--color-error);
            }

    @media screen and (min-width: 900px) {
        .Checkbox-module_container__3PjtG .Checkbox-module_arrow__1wZIj {
            border-width: 20px 15px 0px 15px;
            top: calc(5px + var(--field-padding-x));
        }
}
